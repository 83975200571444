<template>
  <page container-type="centered-sm">
    <page-row>
      <page-column>
        <b-alert show variant="danger" class="mb-4">
          <h4>{{ $t('view.home.noAgency.title') }}</h4>
          <p v-markdown.inline="$t('view.home.noAgency.message')" />
        </b-alert>

        <p v-markdown="$t('view.home.noAgency.comment')" class="mb-5"></p>

        <iam-connected-btn href="/api/openid/connect" class="mb-3">
          {{ $t('view.home.login.button') }}
        </iam-connected-btn>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { BAlert } from 'bootstrap-vue';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import iamConnectedBtn from 'poronline-shared-vue-components/components/iam-connected-btn';
import Page from '../components/page/page';

export default {
  name: 'NoAgencyPage',
  components: { Page, PageRow, PageColumn, iamConnectedBtn, BAlert },
};
</script>
